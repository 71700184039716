import QueryString from "qs";
import React from "react";
import qs from "qs";

export type MetaRequestContextProps = {
    userAgent: string | undefined,
    currentSearchQuery: string | undefined,
    updateCurrentSearchQuery: (query: string) => void,
    getQuery: () => QueryString.ParsedQs
}
export const MetaRequestContext = React.createContext<MetaRequestContextProps>({
    userAgent: "",
    currentSearchQuery: "",
    updateCurrentSearchQuery: () => {},
    getQuery: () => ({})
})
export const useRequestMeta = () => React.useContext(MetaRequestContext)
