'use client';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import React, { PropsWithChildren, useRef } from 'react';
import { LinkProps } from 'next/link';
import { useLocale } from 'next-intl';
import { GtmEvent, trackGtmEvent } from '@/src/helpers/analytics.ts';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@/src/lib/utils.ts';

const linkVariant = cva('', {
  variants: {
    variant: {
      default: '',
      link: 'underline',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type ClientLinkProps = {
  onPageActive?: 'NOLINK' | 'HIDE' | 'KEEP';
  clickTrackGtmEvent?: GtmEvent;
  arrow?: boolean;
} & VariantProps<typeof linkVariant> &
  LinkProps;

function ensureTrailingSlash(url: string): string {
  // Prüfen, ob der URL bereits mit einem "/" endet
  if (url.endsWith('/')) {
    return url;
  }

  // Prüfen, ob der URL Query-Parameter enthält
  const urlParts = url.split('?');
  if (urlParts.length == 2) {
    // Wenn Query-Parameter vorhanden sind, fügen Sie den "/" vor den Parametern ein
    return `${urlParts[0]}/?${urlParts[1]}`;
  } else {
    // Wenn keine Query-Parameter vorhanden sind, einfach den "/" am Ende hinzufügen
    return `${url}/`;
  }
}

export default function ClientLink(
  props: PropsWithChildren<ClientLinkProps & React.AnchorHTMLAttributes<any>>,
) {
  const {
    children,
    clickTrackGtmEvent,
    onPageActive = 'NOLINK',
    href,
    variant,
    arrow,
    className,
    ...other
  } = props;

  const currentLocale = useLocale();
  const { Link, pathnames, usePathname } = useLanguageContext();
  const currentPath = usePathname();

  const prependUrlSlash = (pathname: string) => {
    return pathname !== '/' && pathname.slice(-1) != '/'
      ? pathname + '/'
      : pathname;
  };

  let pathname = props.href;
  const url = new URL(pathname, 'http://dummy.de');
  pathname = prependUrlSlash(url.pathname);

  const existingTranslations = Object.entries(pathnames[pathname] || {})
    .filter(([k, v]) => !!v)
    .map(([k, v]) => k);

  let overwriteLocale = undefined;

  if (!existingTranslations.includes(currentLocale)) {
    overwriteLocale = 'de';
  }

  const onClick = (event: React.MouseEvent) => {
    if (event && isModifiedEvent(event)) return;

    window.dispatchEvent(new Event('pageTransitionStart'));
    // linkRef.current.click();
    if (clickTrackGtmEvent) trackGtmEvent(clickTrackGtmEvent);
  };
  const linkRef = useRef<HTMLLinkElement>();

  const classNameCalc = cn('group', className, linkVariant({ variant }));

  const renderNoLink = () => {
    return <span className={classNameCalc}>{children}</span>;
  };

  if (href == '#') return renderNoLink();

  // Don't wrap in link if the URL is same
  if (currentPath === pathname) {
    if (onPageActive === 'NOLINK') {
      return renderNoLink();
    } else if (onPageActive === 'HIDE') {
      return <></>;
    }
  }

  return (
    <Link
      ref={linkRef}
      href={href.startsWith('http') ? href : ensureTrailingSlash(href)}
      onMouseDown={onClick}
      locale={overwriteLocale}
      {...(other as any)}
      className={classNameCalc}
    >
      {children}
      {arrow && (
        <span className="inline-block pl-1 transition-transform group-hover:translate-x-1">
          &raquo;
        </span>
      )}
    </Link>
  );
}

function isModifiedEvent(event: React.MouseEvent): boolean {
  const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement;
  const target = eventTarget.getAttribute('target');
  return (
    (target && target !== '_self') ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey || // triggers resource download
    (event.nativeEvent && event.nativeEvent.button === 1)
  );
}
