import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/src/lib/utils';

const badgeVariants = cva(
  'inline-flex items-center cursor-default rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary dark:bg-secondary/90 text-secondary-foreground/85 hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: '',
      },
      size: {
        normal: '',
        lg: 'py-1 px-3 text-md',
        xl: 'py-2 px-4 text-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'normal',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  withArrow?: boolean;
}

function Badge({
  className,
  variant,
  size,
  children,
  withArrow,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className, 'group')}
      {...props}
    >
      {children}
      {withArrow && (
        <span className="ml-2 !transition-transform duration-500 group-hover:translate-x-0.5">
          &raquo;
        </span>
      )}
    </div>
  );
}

export { Badge, badgeVariants };
