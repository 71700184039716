import {ReactComponent as Logo} from "@/public/images/logo-only.svg";
import {ReactComponent as LogoTextWhite} from "@/public/images/logo-text-white.svg";
import {ReactComponent as LogoTextBlack} from "@/public/images/logo-text-black.svg";
import React from "react";

export default function LogoWithText(
    {
        darkMode = false,
        ...props
    }: {
        darkMode: boolean
    } & React.HTMLAttributes<HTMLSpanElement>) {
    
    const LogoText = darkMode
        ? LogoTextWhite
        : LogoTextBlack;
    
    return (
        <span className="h-full relative inline-flex flex-row items-center">
            <Logo height="100%" width="35%" className="h-full"  />
            <LogoText className="relative top-[12%]" height="60%" width="50%" />
        </span>
    )
}
