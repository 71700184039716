'use client';

import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { cn, isSSR } from '@/src/lib/utils.ts';
import ClientLink from '@/src/components/layout/ClientLink.tsx';
import NoSSR from '@mpth/react-no-ssr';
import { useTranslations } from '@/src/hooks/useTranslations.tsx';
import { useListenWindowEvent } from '@/src/hooks/useListenWindowEvent.ts';
import { trackGtmEvent } from '@/src/helpers/analytics.ts';
import { useNavigationContext } from '@/src/components/layout/navigation/Navigation.tsx';

const Burger = ({
  className,
  isNavOpen,
  ...rest
}: {
  isNavOpen: boolean;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={cn(className, 'tham tham-e-squeeze tham-w-9', {
      'tham-active': isNavOpen,
    })}
  >
    <div className="tham-box">
      <div className="tham-inner dark:bg-white" />
    </div>
  </div>
);

type FullsizeMenuProps = { className?: string; menuOpen: boolean };

export default function FullsizeMenu({
  className,
  menuOpen,
}: FullsizeMenuProps) {
  const trans = useTranslations();
  const { setFullsizeMenuOpen, isFullsizeMenuOpen } = useNavigationContext();

  useListenWindowEvent('pageTransitionStart', () => {
    setFullsizeMenuOpen(false);
  });

  const portal =
    !isSSR() &&
    createPortal(
      <div
        className={cn(
          'dark invisible fixed top-0 z-40 h-full w-full overflow-auto bg-black/90 opacity-0 transition-all duration-300',
          { 'visible opacity-100': isFullsizeMenuOpen },
          // {"hidden": !isNavOpen},
        )}
      >
        <div
          className={cn(
            'text-foreground flex h-screen flex-col items-center justify-center gap-10 align-middle',
          )}
        >
          <ClientLink
            onClick={() => setFullsizeMenuOpen(false)}
            clickTrackGtmEvent={{ event: 'navLinkClick', link: '/' }}
            onPageActive="HIDE"
            className="text-3xl font-bold uppercase"
            href="/"
          >
            {trans('NAVIGATION.DISCOVERY')}
          </ClientLink>
          <ClientLink
            onClick={() => setFullsizeMenuOpen(false)}
            clickTrackGtmEvent={{ event: 'navLinkClick', link: '/journal' }}
            onPageActive="HIDE"
            className="text-3xl font-bold uppercase"
            href="/journal"
          >
            {trans('NAVIGATION.JOURNAL')}
          </ClientLink>
        </div>
      </div>,
      document.body,
    );

  return (
    <>
      <Burger
        isNavOpen={menuOpen}
        onClick={() => {
          setFullsizeMenuOpen(!isFullsizeMenuOpen);
          trackGtmEvent({ event: 'navBurgerClick' });
        }}
        className={cn(className)}
      />
      <NoSSR>{portal}</NoSSR>
    </>
  );
}
