import {useEffect} from "react";

type HookListener = (e: unknown) => void

export function useListenWindowEvent(event: string, onHook: HookListener) {
    
    useEffect(() => {
        
        const listener = (e: any) => {
            onHook(e);
        }
        
        window.addEventListener(event, listener)
        return () => removeEventListener(event, listener)
        
    }, []);
    
}
