import {
  NavigationMenuLink,
  navigationMenuTriggerStyle,
} from '@/src/components/ui/navigation-menu.tsx';
import React from 'react';
import { NavigationItem } from '@/src/components/layout/navigation/navigation-items.tsx';
import { Separator } from '@/src/components/ui/separator';
import ClientLink from '@/src/components/layout/ClientLink.tsx';
import { Badge } from '@/src/components/ui/badge';
import { useTranslations } from '@/src/hooks/useTranslations';
import { cn } from '@/src/lib/utils.ts';

type Props = {
  items: NavigationItem[];
};

function NavigationProductItem({ item }: { item: NavigationItem }) {
  return (
    <li>
      <ClientLink
        clickTrackGtmEvent={{
          event: 'navLinkClick',
          link: item.url ?? 'no-url',
          label: item.label,
        }}
        href={item.url ?? '#'}
      >
        <NavigationMenuLink>
          <div
            className={cn(
              'group flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b px-4 py-3 no-underline outline-none focus:shadow-md',
              !item.upcoming &&
                'from-foreground/10 to-foreground/15 hover:to-foreground/10',
              item.upcoming && 'bg-muted/90',
            )}
          >
            <p
              className={cn(
                'text-foreground/90 mb-1 flex flex-row items-center gap-3 text-xl font-semibold ',
                !item.upcoming &&
                  'transition-transform group-hover:translate-x-1',
              )}
            >
              {item.icon && item.icon()}
              {item.label}
              {item.upcoming && <Badge variant="secondary">Upcoming</Badge>}
            </p>
            <p className="text-muted-foreground">{item.description}</p>
          </div>
        </NavigationMenuLink>
      </ClientLink>
    </li>
  );
}

export function NavigationProductsSlidingMenu({ items }: Props) {
  const trans = useTranslations();

  return (
    <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[650px] lg:grid-cols-[.5fr_1fr]">
      <li className="row-span-5">
        <NavigationMenuLink asChild>
          <div className="from-muted/20 to-muted/60 flex h-full w-full select-none flex-col justify-center rounded-md bg-gradient-to-b p-6 no-underline outline-none focus:shadow-md">
            <div className="text-secondary mb-2 mt-4 flex flex-row items-center gap-2 text-3xl font-medium">
              {trans('NAVIGATION.Produkte')}
            </div>
            <p className="text-muted-foreground text-sm leading-tight">
              {trans(
                'NAVIGATION.Wir helfen dir mit nützlichen Tools die dich beim Marketing unterstützen',
              )}
            </p>
          </div>
        </NavigationMenuLink>
      </li>
      {items
        .filter((e) => e.label !== 'Journal')
        .map((item, key) => (
          <NavigationProductItem key={key} item={item} />
        ))}

      <Separator className="my-4" />
      {items
        .filter((e) => e.label === 'Journal')
        .map((item, key) => (
          <NavigationProductItem key={key} item={item} />
        ))}
    </ul>
  );
}
