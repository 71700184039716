import { useEffect, useState } from 'react';

export function useDarkMode([menuDarkMode, setMenuDarkMode]: ReturnType<
  typeof useState<boolean>
>) {
  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (darkModeEnabled) {
      document.body.classList.add('dark');
      setMenuDarkMode(true);
    } else {
      document.body.classList.remove('dark');
      setMenuDarkMode(false);
    }
  }, [darkModeEnabled]);

  return { darkModeEnabled, setDarkModeEnabled };
}
