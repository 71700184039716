'use client';

import {
  MenuState,
  useNavigationContext,
} from '@/src/components/layout/navigation/Navigation.tsx';
import { useEffect } from 'react';

export default function NavigationStateChanger({
  state,
  menuDarkMode,
}: {
  state: MenuState;
  menuDarkMode: boolean;
}) {
  const navContext = useNavigationContext();
  useEffect(() => {
    navContext.setState(state);
  }, [state]);

  useEffect(() => {
    navContext.setMenuDarkMode(menuDarkMode);
  }, [menuDarkMode]);

  return <></>;
}
