import { ReactNode, useMemo } from 'react';
import { Search, BookHeart, BarChart } from 'lucide-react';
import {
  TFunction,
  TResult,
} from '@/src/components/layout/ProvideLanguageContext.tsx';
import { useTranslations } from '@/src/hooks/useTranslations';

export type NavigationItem = {
  icon?: () => ReactNode;
  upcoming?: boolean;
  label: string;
  url?: string;
  description: string | TResult;
};

export function useMenu(accessor: (...args: unknown[]) => NavigationItem[]) {
  const trans = useTranslations();

  return useMemo(() => {
    return accessor(trans);
  }, []);
}

export const DiscoveryMenu = (trans: TFunction) =>
  [
    {
      icon: () => <Search />,
      label: 'Discovery',
      url: '/discovery',
      description: trans(
        'NAVIGATION.Nutze die Kraft der künstlichen Intelligenz um Influencer zu finden&',
      ),
    },
    {
      label: 'Analysis',
      icon: () => <BarChart />,
      upcoming: true,
      // url: '/journal',
      description: trans(
        'NAVIGATION.Erhalte Detailanalysen zu Influencern welche dir wirklich nützen&',
      ),
    },
    {
      label: 'API',
      icon: () => <BarChart />,
      upcoming: true,
      // url: '/journal',
      description: trans(
        'NAVIGATION.Erhalte Zugriff auf über 100k klassifizierte Social-Media-Profile&',
      ),
    },
    {
      label: 'Journal',
      icon: () => <BookHeart />,
      url: '/journal',
      description: trans(
        'NAVIGATION.Immer auf dem neusten Stand& Relevante Artikel über die neusten Trends&',
      ),
    },
    // {
    //   label: 'Discovery',
    //   url: '/discovery',
    //   description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
    // },
  ] satisfies NavigationItem[];
